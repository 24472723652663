<template lang="html">
  <div style="height: 100%">
    <PlantInfo :plant-page="true" :plantId="Number(this.$route.params.plantId)"/>
  </div>
</template>

<script>
import PlantInfo from "@/components/plants/PlantInfo";

export default {
  components: {
    PlantInfo
  },
}
</script>